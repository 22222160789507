<template>
  <div class="artist position-relative">
    <div v-if="isDesktop" class="pt-5">
      <h6>Music</h6>
    </div>
    <div class="container">
      <div
        :class="isDesktop ? 'mt-4 w-85 block-center-absolute' : null"
        class="row"
      >
        <div
          v-for="(artist, index) in artists"
          :key="index"
          class="col-12 col-md-6 col-lg-4"
        >
          <div
            :class="{
              'mb-4 pb-4': !isDesktop,
              
            }"
            class="box-artist flex-content align-items-center"
          >
            <div
              v-if="artist.active"
              class="mt-3"
            >
              <h5  class="name-artist">
                {{ artist.name }}
              </h5>
              <p
                class="description"
                v-if="$i18n.locale == 'en'"
                v-html="artist.description_en"
              ></p>
              <p
                class="description"
                v-if="$i18n.locale == 'it'"
                v-html="artist.description_it"
              ></p>
            </div>
            <div v-else class="mt-4">
              <h5 class="name-artist">TBA</h5>
            </div>
            <div v-if="artist.active" class="image-container">
              <a :href=artist.link target="_blank">

                <img :src="artist.imgArtist" alt="" />
              </a>
            </div>
            <div class="image-default" v-else>
              <img
                class="w-100"
                src="../assets/img/artists/Vector 49.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  mixins: [checkViewport],
  name: "BoxArtist",
  data() {
    return {
      artists: [
        {
          imgArtist: "/img/artists/Artist1.png",
          name: "Ginevra Nervi",
          description_en:
            "“The Disorder of Appearances” <br />  A/V Live Performance",
          description_it:
            "“The Disorder of Appearances” <br />  A/V Live Performance",
          link: "https://www.youtube.com/watch?v=BWR_1_3hUoQ",
            active: true
        },
        {
          imgArtist: "/img/artists/Artist2-1.png",
          name: "PIETRO VICENTINI",
          description_en:
            "Real Timpani presents: <br /> P.Vincentini - Live",
          description_it:
          "Real Timpani presenta: <br /> P.Vincentini - Live",
          link: "https://open.spotify.com/intl-it/artist/7n47nWRWiMl1rbgspp7YsA",
            active: true
        },
        {
          imgArtist: "/img/artists/Artist3.png",
          name: "KURA",
          description_en:
            "Dj set",
          description_it:
            "Dj set",
          link: "https://soundcloud.com/kuraprjkt",
            active: true
        },
        {
          imgArtist: "/img/artists/Artist4.png",
          name: "Ya Hu",
          description_en:
            "Dj set",
          description_it:
            "Dj set",
          link: "https://soundcloud.com/yasminhul",
            active: true
        },
        {
          imgArtist: "/img/artists/Artist5.png",
          name: "TOUF",
          description_en:
            "Dj set",
          description_it:
            "Dj set",
          link: "#",
            active: true
        },
        {
          imgArtist: "/img/artists/Artist7.png",
          name: "ELENA COLOMBI",
          description_en:
            "Dj set",
          description_it:
            "Dj set",
          link: "https://test-deserto.mastfestival.com/#/",
            active: true
        },
        /* {
          imgArtist: "/img/artists/Artist6.png",
          name: "Knarzy",
          description_en:
            "<br>KNARZY<br><br>Knarzy’s identity - originally from Modica based in Bologna - was born during three years of pure academy of the Electronic Music classes at Bologna Conservatory; she developed her performances as a benchmark between basslines acid and IDM sounds and rhythms.",
          description_it:
            "<br>KNARZY<br><br>L’identità di Knarzy - modicana di base a Bologna - prende forma durante i 3 anni di puro accademismo del corso di musica eletrronica del conservatorio di Bologna, e sviluppa le sue performance come un’unione di basslines Acid e suoni e ritmi macchinosi della cosiddetta IDM (Intelligent Dance Music).",
          showDescription: false,
          link: "#",
          active: false
        },
        {
          imgArtist: "/img/artists/Artist1.png",
          name: "Amine K",
          description_en:
            "<br>Amine K<br><br>Morocco’s Ambassador for electronic music Happiness is the core of everything he does. He embraced the unknown, panting electronic music flags around  the globe helping developing real underground communities from Myanmar to Mauritious.",
          description_it:
            "<br>Amine K<br><br>L’ambasciatore della musica elettronica in Marocco. La felicità sta alla base di ogni cosa che fa. Accogliendo sempre l’innovazione e lo sconosciuto, ha piantato bandiere di musica elettronica in tutto il mondo, supportando lo sviluppo di comunità underground dal Myanmar alle Mauritious.",
          showDescription: false,
          link: "#",
          active: false
        }, */
      ],
    };
  },
  mounted() {
    console.log("ecc");
  },
};
</script>

<style></style>
