<template>
  <div>
    <Header @sectionClick="onSectionClick" />
    <div class="discover">
      <div  class="panel">
        <Aere />
      </div>

      <div id="aere" class="panel area-text">
        <AereaSecond />
      </div>
      <div class="panel">
        <AereaThird />
      </div>
      <section id="artists" class="panel artist-text background-colored">
        <Artist />
      </section>
      <div v-if="!isDesktop" class="artist pt-5">
        <div
          class="pb-2"
          :style="
            artistType == 'box'
              ? {
                  'border-bottom': '4px solid var(--dark-font-color)',
                  width: 'fit-content',
                }
              : null
          "
        >
        <h2 @click="artistType = 'box'" :style="{ color: artistType == 'box' ? 'var(--dark-font-color)' : '' }">{{ $t('artist.music') }}</h2>

        </div>
        <br />
        <div
          class="pb-2"
          :style="
            artistType == 'list'
              ? {
                  'border-bottom': '4px solid var(--dark-font-color)',
                  width: 'fit-content',
                }
              : null
          "
        >
        <h2 @click="artistType = 'list'" :style="{ color: artistType == 'list' ? 'var(--dark-font-color)' : '' }">{{ $t('artist.art') }}</h2>


        </div>
      </div>
      <section v-once v-if="artistType == '' || artistType == 'box'" class="panel box-image background-colored position-relative">
        <BoxArtist />
        <img v-if="isDesktop" class="astract-img-artist" src="../assets/img/group/img8.svg" alt="">
      </section>
      <section v-if="artistType == '' || artistType == 'list'" class="panel background-colored">
        <ListArtist />
      </section>
      <section  class="panel">
        <Ticket />
      </section>
      <section id="tour" class="panel tour-text">
        <Tour />
      </section>
      <section class="panel">
        <StageTour />
      </section>
      <section id="contacts" class="panel">
        <Contacts />
      </section>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import checkViewport from "@/mixins/checkViewport";
import Aere from "@/components/Aere.vue";
import AereaSecond from "@/components/AereaSecond.vue";
import AereaThird from "@/components/AereaThird.vue";
import Artist from "@/components/Artist.vue";
import BoxArtist from "@/components/BoxArtist.vue";
import ListArtist from "@/components/ListArtist.vue";
import Ticket from "@/components/Ticket.vue";
import Contacts from "@/components/Contacts.vue";
import Tour from "@/components/Tour.vue";
import StageTour from "@/components/StageTour.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "Discover",
  mixins: [checkViewport],
  data() {
    return {
      scrollTween: null,
      boxArtist: true,
      listArtist: false,
      artistType: "",
      
    };
  },
  components: {
    Header,
    Aere,
    AereaSecond,
    AereaThird,
    Artist,
    BoxArtist,
    ListArtist,
    Ticket,
    Contacts,
    Tour,
    StageTour,
  },
  mounted() {
    const app = this;
    if (app.isDesktop) {
      app.callGsap();
    }else{
      this.artistType = 'box'
      console.log('=>',this.artistType);
    }
  },
  async beforeDestroy() {
    console.log(this.$route);
    if (this.$route.path == "/") window.location.reload();
  },
  methods: {
    callGsap() {
      gsap.registerPlugin(ScrollTrigger);
      let sections = gsap.utils.toArray(".panel");
      this.scrollTween = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".discover",
          pin: true,
          scrub: 1,
          horizontal: false,
          end: () => "+=" + document.querySelector(".discover").offsetWidth*2,
        },
      });
      const firstText = document.querySelector(".area-text h2");
      gsap.from(firstText, {
        opacity: 0,
        scrollTrigger: {
          trigger: firstText,
          start: innerWidth * 0.15,
          scrub: true,
          end: "+=300",
          /* markers:true, */ 
          onEnter: () => {
            firstText.classList.add("animate__fadeInDownBig");
          },
         
        },
      });
      const centerText = document.querySelector(".artist-text h2");
      gsap.from(centerText, {
        opacity: 0,
        scrollTrigger: {
          trigger: centerText,
          start: innerWidth * 0.58,
          scrub: true,
          end: "+=200",
          /* markers: true, */
          onEnter: () => {
            centerText.classList.add("animate__fadeInLeft");
          },
         
        },
      });
      
      const lastText = document.querySelector(".tour-text h2");
      gsap.from(lastText, {
        opacity:0,
        scrollTrigger: {
          trigger: lastText,
          start: innerWidth * 1.40,
          scrub: true,
          end: "+=300",
          /* markers:true, */
        
          onEnter: () => {
            lastText.classList.add("animate__fadeInLeft");
          },
        },
      });
    },
    onSectionClick(position) {
      console.log(position);
      let progress = position; // or whatever
      this.scrollTween.scrollTrigger.scroll(
        this.scrollTween.scrollTrigger.start +
          progress *
            (this.scrollTween.scrollTrigger.end -
              this.scrollTween.scrollTrigger.start)
      );
      console.log(
        "this.scrollTween.scrollTrigger",
        this.scrollTween.scrollTrigger
      );
    },
  },
};

</script>
<style>
.box-image {
  will-change: transform;
}
</style>