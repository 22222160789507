<template>
  <div class="aere">
    <div :class="{'justify-content-center' : isTablet}" class="left-area d-flex align-items-center">
      
     <img class="static-img" src="../assets/img/mappa-02.png" alt="">
    </div>
    <!-- <div :class="{'p-5' : isDesktop, 'mt-5' :!isDesktop}" class="right-area d-flex flex-column-reverse">
        <ul>
            <li class="medium-text">01.chiesa di san matteo</li>
            <li class="medium-text">02.chiesa di san matteo</li>
            <li class="medium-text">03.chiesa di san matteo</li>
            <li class="medium-text">04.chiesa di san matteo</li>
        </ul>
        <h3  :class="{'ms-4 mb-4' : isDesktop, 'ms-3' :!isDesktop}" >Tappe</h3>
      
    </div> -->
    <!-- <div class="right-area d-flex justify-content-center  align-items-center static-map">
      <h4>TOUR <br> STAGES TBA</h4>
    </div> -->
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
    name:'StageTour',
  mixins: [checkViewport],

}
</script>

<style>
.static-img{
  height: 100%;
  max-height:950px ;
  max-width: 950px;
  object-fit: contain;
}
.static-map{
  position: relative;
  
}
.static-map h4{
  position: absolute;
  bottom: 20%;
  left: 100px;
  z-index: 999;
  text-align: start;
}
</style>