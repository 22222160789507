import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Discover from "../views/Discover.vue";
/* import ComingSoon from "../views/ComingSoon.vue"; */


Vue.use(VueRouter);

const routes = [

/*   {
    path: "/",
    name: "ComingSoon",
    component: ComingSoon,
  }, */
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/discover",
    name: "Discover",
    component: Discover,
  },
];

const router = new VueRouter({
  routes,
  // eslint-disable-next-line no-unused-vars
  /* scrollBehavior(to, from) {
    if (to.hash) {
      // BEFORE:
      // console.log(to);
      console.log(to.hash);
      return { selector:  to.hash, behavior:'auto'  };
    }
  }, */
});

export default router;

