<template>
  <div class="home bk-pixel">
    <video autoplay muted playsinline loop id="background-video">
      <source src="../assets/movie/sfondo-mast.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="top">
      <img src="../assets/img/M.svg" alt="" />
      <img src="../assets/img/A.svg" alt="" />
    </div>
    <div
    
      :class="{ 'justify-content-end': !isDesktop }"
      class="center block-center-absolute "
    >
      <p v-if="isDesktop" class="color-pink font-primary">
        © 2024 <br />
        {{ $t("home.dateMonth") }} {{ $t("home.dateDay") }} <br />
        scicli (rg)
      </p>

      <p v-if="isDesktop" class="font-primary" v-html="$t('home.text')"></p>
      <transition name="fade">
        <p
          v-if="!isDesktop"
          class="special-text"
          v-html="$t('home.text')"
        ></p>
      </transition>

      <a v-if="isDesktop" class="ms-5 pulse" href="#/discover"
        ><svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 1L9 5L5 9" stroke="currentColor" />
          <path d="M9 5L0 5" stroke="currentColor" />
        </svg>
        Discover</a
      >
      <div :class="{ 'd-flex flex-column align-items-center': !isDesktop }">
        <div class="btn">
          <a href="https://xceed.me/it/scicli/events/all/all-events" target="_blank">{{ $t("ticket.text") }}</a>
        </div>
        <a v-if="!isDesktop" class="mt-3 color-pink pulse" href="#/discover"
          ><svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 1L9 5L5 9" stroke="currentColor" />
            <path d="M9 5L0 5" stroke="currentColor" />
          </svg>
          Discover</a
        >
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/img/S.svg" alt="" />
      <img src="../assets/img/T.svg" alt="" />
    </div>

  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";
/* import Header from "../components/Header.vue"; */
export default {
  /* components: { Header }, */
  name: "Home",
  data() {
    return {
      videoElement: null,
     
      /* paused: null, */
    };
  },
  mixins: [checkViewport],
  mounted() {
    document
      .getElementsByClassName("home")[0]
      .addEventListener("touchmove", function (event) {
        event.preventDefault();
        /* this.test(); */
      });
  },

};
</script>

<style scoped lang="scss">
.special-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Blur"!important;
}
.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-enter {
  opacity: 0;
}
</style>
