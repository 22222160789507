<template>
    <div class="tour position-relative flex-content align-items-center">
      <h2 :class="['abs-tp', 'animate__animated', !isDesktop ? 'w-100' : 'w-50 ms-5 ps-5']" >Tour</h2>

        <div :class="{'w-35 abs-btm' : isDesktop}">
        <p v-html="$t('tour.text')">
        </p>
      </div>
    </div>
  </template>
  
  <script>
  import checkViewport from "@/mixins/checkViewport";
/*   import { gsap } from "gsap"; */
  
  
  export default {
    name: "Tour",
    mixins: [checkViewport],
    mounted() {
    
    },
    methods: {

    },
  
  };
  </script>
  
  <style>
  </style>
  