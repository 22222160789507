<template>
  <div class="contacts">
    <div :class="{'p-4 ms-5' : isDesktop}" class="d-flex justify-content-between align-items-end">
      <h2 :class="{'flex-column' :!isDesktop}">
          {{ $t('menu.contact') }}
      </h2>
      <a href="https://yomi.digital/#/" target="_blank" v-if="isDesktop"  class=" yomi text-uppercase">developed w/ love by yomi</a>
    </div>
    <div :class="!isDesktop ? 'flex-column h-50 ms-0 p-0' : 'ms-5 p-4'" class="d-flex justify-content-between navigate-contacts">
      <div>
        <p class="text-uppercase">
          {{ $t('contact.text') }}

        </p>
      </div>
      <div>
        <a href="mailto:Info.mastfestival@gmail.com">Info.mastfestival@gmail.com</a>
      </div>
      <div>
        <ul :class="{'p-0' :!isDesktop}" class="social">
          <li :class="{'ms-0' :!isDesktop}">
            <a href="https://www.facebook.com/mastfest22" target="_blank"><img class="social" src="../assets/img/facebook.svg" alt="" /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/mast_fest/" target="_blank"><img class="social" src="../assets/img/instagram.svg" alt="" /></a>
          </li>
          <!-- <li>
            <a href="#"><img src="../assets/img/linkedin.svg" alt="" /></a>
          </li> -->
        </ul>
      </div>
      <div id="yomi" v-if="!isDesktop">
      <a href="https://yomi.digital/#/" target="_blank" v-if="!isDesktop" class=" yomi text-uppercase">developed w/ love by yomi</a>

      </div>
    </div>
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";
export default {
  name: "Contacts",
  mixins: [checkViewport],

};
</script>

<style></style>
