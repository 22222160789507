<template>
  <div class="aere">
    
    <div
    class="right-area container-padding absolute-content" 
    >
    <h2 v-if="isDesktop" class="abs-tp animate__animated">
        {{ $t("home.text1") }} <br />
        {{ $t("home.text2") }}  <br />
        {{ $t("home.text3") }}
      </h2>
      <h2 v-if="!isDesktop" class="abs-tp animate__animated" v-html=" $t('home.text')"></h2>

      <div :class="{ 'w-65 abs-btm': isDesktop }" class="box-area pe-4">
        <p>
          {{ $t("aere.text2") }}
        </p>
      </div>


    </div>
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  name: "AeraSecond",
  mixins: [checkViewport],
};
</script>

<style></style>
