<template>
  <header id="header">
    <div class="container-fluid">
      <div class="row">
        <div
          :class="{ 'd-flex justify-content-between': !isDesktop }"
          class="col-12 col-md-12 col-lg-2"
        >
        <a :class="{ 'text-center': !isDesktop }" href="https://mastfestival.com/#/" target="_blank">
            <img v-if="!showMenu" src="../assets/img/logo.svg" alt="" />
            <img
              v-if="!isDesktop & showMenu"
              src="../assets/img/logo-white.svg"
              alt=""
            />
          </a>
          <div
            @click="
              showMenu = !showMenu;
              activeMenu();
            "
            v-if="!isDesktop"
          >
            <img
              v-if="!showMenu"
              style="width: 25px"
              src="../assets/img/menu.svg"
              alt=""
            />
            <img
              v-if="!isDesktop & showMenu"
              style="width: 25px"
              src="../assets/img/close.svg"
              alt=""
            />
          </div>

         
          <!-- <div v-if="!isDesktop"></div> -->
        </div>
        <div v-if="isDesktop || showMenu" class="col-0 col-md-8 menu-mobile">
          <ul class="header-menu-mobile">
            <li>
              <a v-if="$route.path === '/'" :href="`/#/discover?position=0.0`"
                >
                {{ $t('menu.desert') }}</a
              >
              <a
                v-else
                @click="
                  isDesktop ? onSectionClick(0.0) : onSectionClickMobile('aere')
                "
              >
             
                {{ $t('menu.desert') }}</a
              >
            </li>
            <li>
              <a
                v-if="$route.path === '/'"
                :href="`/#/discover?position=0.445`"
                >
                {{ $t('menu.art') }}</a
              >

              <a
                v-else
                @click="
                  isDesktop
                    ? onSectionClick(0.34)
                    : onSectionClickMobile('artists')
                "
                >
                {{ $t('menu.art') }}</a
              >
            </li>
            <li>
              <a
                v-if="$route.path === '/'"
                :href="`/#/discover?position=0.74`"
                >
                tour</a
              >
              <a
                v-else
                @click="
                  isDesktop
                    ? onSectionClick(0.78)
                    : onSectionClickMobile('tour')
                "
                >
                tour</a
              >
            </li>
            <li>
              <a
                v-if="$route.path === '/'"
                :href="`/#/discover?position=1.0`"
                >
                {{ $t('menu.contact') }}</a
              >
              <a
                v-else
                @click="
                  isDesktop
                    ? onSectionClick(1.0)
                    : onSectionClickMobile('yomi')
                "
                >
                {{ $t('menu.contact') }}</a
              >
            </li>
            <li v-if="isDesktop">
              <a href="https://xceed.me/it/scicli/events/all/all-events" target="_blank">
                
                {{$t('ticket.text')}}
              </a>
            </li>
            <li v-if="!isDesktop">
              <div class="d-flex justify-content-center">
                <p @click="handleChangeEn()" class="text-lang color-link font-primary">EN</p>
                <p style="pointer-events: none;" class="text-lang">&nbsp;/&nbsp;</p>
                <p @click="handleChangeIt()" class="text-lang color-link font-primary">IT</p>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="isDesktop" class="col-0 col-md-2 text-end">
          <div class="d-flex justify-content-end align-items-center h-100">
            <p @click="handleChangeEn()" class="text-lang color-link font-primary">EN</p>
            <p style="pointer-events: none;" class="text-lang">&nbsp;/&nbsp;</p>
            <p @click="handleChangeIt()" class="text-lang color-link font-primary">IT</p>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import checkViewport from "@/mixins/checkViewport";
/* import { gsap } from "gsap"; */

export default {
  name: "Header",
  mixins: [checkViewport],

  data() {
    const lang = localStorage.getItem("lang");
    return {
      lang: lang,
      showMenu: false,
    };
  },
  beforeDestroy() {
    const app = document.getElementById("app");
    if (!app.isDesktop) {
      const header = document.getElementById("header");
      if (app) {
        app.style.overflowY = "initial";
        app.style.position = "static";
        app.style.width = "auto";
      }
      if (header) {
        header.style.top = "0px";
        header.style.background = "var(--font-color)";
      }
    }
  },
  mounted() {
    const app = this;
    if (app.$route.hash != "") {
      const el = document.getElementById(app.$route.hash.replace("#", ""));
      el.scrollIntoView();
      console.log(app.$route.hash);
    }
  },

  methods: {

    onSectionClickMobile(elementId) {
      this.showMenu = !this.showMenu;
      this.activeMenu();
      const el = document.getElementById(elementId);
      el.scrollIntoView();
    },
    onSectionClick(position) {
      if (this.isDesktop) {
        this.$emit("sectionClick", position);
      }
    },
    handleChangeEn() {
      localStorage.setItem("lang", "en");
      window.location.reload();
    },
    handleChangeIt() {
      localStorage.setItem("lang", "it");
      window.location.reload();
    },
    activeMenu() {
      const app = this;
      if (!app.isDesktop) {
        if (app.showMenu) {
          document.getElementById("app").style.overflow = "hidden";
          document.getElementById("app").style.position = "fixed";
          document.getElementById("app").style.width = "100%";
          document.getElementById("app").style.height = "100%";
          document.getElementById("header").style.background =
            "var(--primary-color)";
        } else {
          document.getElementById("app").style.overflow = "scroll";
          document.getElementById("app").style.position = "static";
          document.getElementById("app").style.width = "auto";
          document.getElementById("app").style.height = "auto";
          document.getElementById("header").style.background =
            "var(--colored)";
        }
      }
    },
  },
};
</script>
<style></style>
