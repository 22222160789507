<template>
  <div class="artist position-relative">
    <div v-if="isDesktop" class="pt-5 ps-5">
      <h6>{{ $t("artist.art&com")}}</h6>
    </div>
    <div class="container box-scroll">
      <div
        :class="isDesktop ? 'pt-3' : null"
        class="row single-event"
        v-for="(event, index) in events"
        :key="index"
      >
        <div
          class="col-12 col-md-10 col-lg-8 offset-0 offset-md-1 offset-lg-0 position-relative"
        >
          <div class="d-flex justify-content-between">
            <div
              v-if="event.activeList"
              @click="event.showDescription = !event.showDescription"
              class="event-artist"
            >
              <div
                :class="
                  isMobile ? 'align-items-baseline pb-1' : 'align-items-center'
                "
                class="d-flex justify-content-between"
              >
                <h5
                  v-if="$i18n.locale == 'en'"
                  v-html="event.head_en"
                  class="date"
                ></h5>
                <h5
                  v-if="$i18n.locale == 'it'"
                  v-html="event.head_it"
                  class="date"
                ></h5>
              </div>
              <!-- <h5 class="day">Day {{ artist }}</h5> -->
              <p
                :class="{ 'color-light': event.showDescription }"
                class="medium-text mt-1 mb-2"
              >
                {{ event.title }}
              </p>

              <p
                class="description pb-3"
                v-if="event.showDescription && $i18n.locale == 'en'"
                v-html="event.description_en"
              ></p>
              <p
                class="description pb-3"
                v-if="event.showDescription && $i18n.locale == 'it'"
                v-html="event.description_it"
              ></p>
              <div
                :class="{ 'd-block': event.showDescription }"
                v-if="!isDesktop && event.activeList"
                class="container-img mb-4"
              >
                <img
                  v-if="!isDesktop"
                  class="default"
                  :src="event.img"
                  alt=""
                />
              </div>
            </div>
            <div v-else class="event-artist" style="cursor: no-drop">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="date">TBA</h5>
              </div>

              <p style="cursor: no-drop" class="medium-text mt-1 mb-2">
                NEXT PERFORMANCE TBA
              </p>
            </div>
            <div
            class="d-flex justify-content-between align-items-center "
            :class="{ 'flex-column': isDesktop, 'flex-column-reverse mb-4 pb-2 mt-2': !isDesktop }"
            >
              <div class="box-date">
                <p>{{ event.day }}</p>
                <p>Ago</p>
              </div>
              <span
                class="font-primary mb-3"
                style="cursor: pointer;"
                @click="event.showDescription = !event.showDescription"
              >
                {{ event.showDescription ? "-" : "+" }}
              </span>
            </div>
          </div>

          <div
            :class="{ 'mt-0': event.showDescription }"
            style="bottom: 0px"
            class="divider-line position-absolute"
          ></div>
        </div>
        <div v-if="isDesktop" class="col-12 col-md-3 col-lg-3">
          <div
            :class="{ 'd-block': event.showDescription }"
            v-if="event.activeList"
            class="container-img"
          >
            <img
              :style="{ opacity: !event.showDescription ? 1 : 0.6 }"
              class="default"
              :src="event.img"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";
export default {
  name: "ListArtist",
  mixins: [checkViewport],
  data() {
    return {
      events: [
        {
          head_it: "PODMAST",
          head_en: "PODMAST",
          title:
            "Tra le Dune di questo Deserto, Podcast by F. Gugliotta, G. Padua, M. Ladduca, A. Ladduca",
          description_it: "Nel cuore pulsante del silenzio, dove la sabbia si fonde con l’orizzonte, nasce il podcast “ Tra le dune di questo deserto”. Un viaggio sonoro che trasforma il deserto da semplice scenario a protagonista di storie, suoni e visioni del futuro.",
          description_en: "In the beating heart of silence, where the sand merges with the horizon, the podcast 'Among the dunes of this desert' is born. A sound journey that transforms the desert from a mere backdrop to a protagonist of stories, sounds and visions of the future.",
          img: "/img/event-artist/PODMAST-SITO.png",
          day: "2-7",
          showDescription: false,
          activeList: true,
        },
        {
          head_it: "D.U.S.T",
          head_en: "D.U.S.T",
          title:
            "Desert Unit for Speculative Territories, Photo Exhibition by N. Licitra & M. Carnemolla",
          description_it:
            "Progetto fotografico sulla desertificazione in Sicilia si propone di esplorare e documentare visivamente l'impatto di questo fenomeno ambientale nella regione, attraverso gli occhi di due fotografi siciliani.",
          description_en:
            "Photographic project on desertification in Sicily aims to visually explore and document the impact of this environmental phenomenon in the region through the eyes of two Sicilian photographers.",
          img: "/img/event-artist/second-event.jpg",
          day: "2-10",
          showDescription: false,
          activeList: true,
        },
        {
          head_it: "RADIO",
          head_en: "RADIO",
          title: "Radio Rhizome x MAST CENTRALE Radio Sessions",
          description_it:
            "La radio, come strumento di diffusione della conoscenza e di condivisione, è un pilastro fondamentale della società contemporanea.",
          description_en:
            "Radio, as a tool for disseminating knowledge and sharing, is a fundamental pillar in contemporary society.",
          img: "/img/event-artist/radio-event.png",
          day: "2-7",
          showDescription: false,
          activeList: true,
        },
        {
          head_it: "Le Onde del Deserto",
          head_en: "Le Onde del Deserto",
          title: "A Mediterranean Sound Research w/ Ya Hu, Artist In Residency",
          description_it: "L’idea è di ospitare l’artista per 10 giorni - l’intera durata del festival - per farla immergere nella reale vita quotidiana di una cittadina del sud come Scicli e fare esperienza della comunità eterogenea che la contraddistingue oggi. ",
          description_en: "The idea is to host the artist for ten days - the whole duration of the festival - to let her immerse in the real daily life of a southern town like Scicli and feel the real heterogeneous community of MAST. ",
          img: "/img/event-artist/onde.jpeg",
          day: "2-10",
          showDescription: false,
          activeList: true,
        },
        {
          head_it: "Pyramid Grid",
          head_en: "Pyramid Grid",
          title:
            "Ephemeral Architecture Main Stage by Ab.usi Studio & A. Marinero",
          description_it: "",
          description_en: "",
          img: "/img/event-artist/fifth-event.jpg",
          day: "10",
          showDescription: false,
          activeList: true,
        },
      ],
    };
  },
};
</script>

<style>
.box-scroll {
  margin-top: 1rem;
  height: 75vh;
  overflow-y: scroll;
}
.box-scroll::-webkit-scrollbar {
  width: 5px;
}

.box-scroll::-webkit-scrollbar-track {
  background: rgba(117, 117, 117, 0.09);
  border-radius: 5px;
}

.box-scroll::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
  transform: rotate(-180deg);
}
</style>
