var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{on:{"sectionClick":_vm.onSectionClick}}),_c('div',{staticClass:"discover"},[_c('div',{staticClass:"panel"},[_c('Aere')],1),_c('div',{staticClass:"panel area-text",attrs:{"id":"aere"}},[_c('AereaSecond')],1),_c('div',{staticClass:"panel"},[_c('AereaThird')],1),_c('section',{staticClass:"panel artist-text background-colored",attrs:{"id":"artists"}},[_c('Artist')],1),(!_vm.isDesktop)?_c('div',{staticClass:"artist pt-5"},[_c('div',{staticClass:"pb-2",style:(_vm.artistType == 'box'
            ? {
                'border-bottom': '4px solid var(--dark-font-color)',
                width: 'fit-content',
              }
            : null)},[_c('h2',{style:({ color: _vm.artistType == 'box' ? 'var(--dark-font-color)' : '' }),on:{"click":function($event){_vm.artistType = 'box'}}},[_vm._v(_vm._s(_vm.$t('artist.music')))])]),_c('br'),_c('div',{staticClass:"pb-2",style:(_vm.artistType == 'list'
            ? {
                'border-bottom': '4px solid var(--dark-font-color)',
                width: 'fit-content',
              }
            : null)},[_c('h2',{style:({ color: _vm.artistType == 'list' ? 'var(--dark-font-color)' : '' }),on:{"click":function($event){_vm.artistType = 'list'}}},[_vm._v(_vm._s(_vm.$t('artist.art')))])])]):_vm._e(),(_vm.artistType == '' || _vm.artistType == 'box')?_vm._m(0):_vm._e(),(_vm.artistType == '' || _vm.artistType == 'list')?_c('section',{staticClass:"panel background-colored"},[_c('ListArtist')],1):_vm._e(),_c('section',{staticClass:"panel"},[_c('Ticket')],1),_c('section',{staticClass:"panel tour-text",attrs:{"id":"tour"}},[_c('Tour')],1),_c('section',{staticClass:"panel"},[_c('StageTour')],1),_c('section',{staticClass:"panel",attrs:{"id":"contacts"}},[_c('Contacts')],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"panel box-image background-colored position-relative"},[_c('BoxArtist'),(_vm.isDesktop)?_c('img',{staticClass:"astract-img-artist",attrs:{"src":require("../assets/img/group/img8.svg"),"alt":""}}):_vm._e()],1)
}]

export { render, staticRenderFns }