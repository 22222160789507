<template>
  <div
    id="aere"
    :class="{ 'mt-0': !isDesktop }"
    class="aere position-relative p-0"
  >
    <div :class="{ h100vh: !isDesktop }" class="left-area">
      <video
        class="myvideo"
        preload="auto"
        loop
        autoplay
        muted
        defaultMuted
        playsinline
      >
        <source
          src="../assets/movie/mast-video-verticale.mp4"
          type="video/mp4"
        />
      </video>
      <div :class="{ 'animation-top': isDesktop }" class="top">
        <img src="../assets/img/M.svg" alt="" />
        <img class="a-effect" src="../assets/img/A.svg" alt="" />
      </div>
      <div
        :class="{ 'animation-center': isDesktop }"
        class="center block-center-absolute flex-column justify-content-center"
      >
        <h4>
          {{ $t("menu.desert") }}<br />
          {{ $t("home.edition") }} <br />
          {{ $t("home.dateMonth") }} <br />
          {{ $t("home.dateDay") }} <br />

          SCICLI (RG)
        </h4>
        <div style="border:none" class="btn full-white m-auto mt-4">
          <a
            href="https://xceed.me/it/scicli/events/all/all-events"
            target="_blank"
            >{{ $t("ticket.text") }}</a
          >
        </div>
      </div>
      <div :class="{ 'animation-bottom': isDesktop }" class="bottom">
        <img src="../assets/img/S.svg" alt="" />
        <img class="t-effect" src="../assets/img/T.svg" alt="" />
      </div>
    </div>
    <div  :class="{'pt-5': !isDesktop, 'ps-5': isDesktop}"
    class="right-area container-padding position-relative  flex-content">
      <div>
        <p
          class="d-flex align-items-center color-light concept font-primary text-uppercase"
        >
          concept
        </p>
      </div>

      <div
        :class="{
          'w-65 abs-btm': isDesktop,
          'mt-5 pt-5 mb-5 pb-5': !isDesktop,
        }"
      >
        <p v-html="$t('aere.text1')"></p>
      </div>

    </div>
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  name: "Aere",
  mixins: [checkViewport],
  mounted() {
    this.$nextTick(() => {
      const video = document.querySelector("video");
      video.addEventListener("ended", function () {
        video.currentTime = video.duration;
      });
      video.play();
    });
  },
};
</script>

<style>
.myvideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.9;
}
</style>
