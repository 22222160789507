import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n";
import { gsap } from "gsap";


import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
//without this line, PixiPlugin and MotionPathPlugin may get dropped by your bundler (tree shaking)...
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const lang = localStorage.getItem("lang") || "en";
document.documentElement.lang = lang;


import "./themes/style.scss";
import "./themes/resp.scss";
import "./fonts/stylesheet.css";
import 'animate.css';



Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
