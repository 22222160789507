<template>
  <div class="artist position-relative flex-content align-items-center">
    <h2 v-if="isDesktop" :class="{ 'w-100': !isDesktop, 'w-50 ms-5 ps-5': isDesktop }" class="abs-tp animate__animated" v-html="$t('menu.artist')"></h2>

     
    <!-- <div :class="{'w-35 abs-btm' : isDesktop}">
      <p>
        {{$t('artist.text') }}
      </p>
    </div> -->

    <img v-if="isDesktop" class="position-absolute start-50 ps-5 animation-snake" src="../assets/img/group/img10.svg" alt="">
  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";



export default {
  name: "Artist",
  mixins: [checkViewport],
  mounted() {
  
  },
  methods: {

  },

};
</script>

<style>

</style>
