var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{attrs:{"id":"header"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-2",class:{ 'd-flex justify-content-between': !_vm.isDesktop }},[_c('a',{class:{ 'text-center': !_vm.isDesktop },attrs:{"href":"https://mastfestival.com/#/","target":"_blank"}},[(!_vm.showMenu)?_c('img',{attrs:{"src":require("../assets/img/logo.svg"),"alt":""}}):_vm._e(),(!_vm.isDesktop & _vm.showMenu)?_c('img',{attrs:{"src":require("../assets/img/logo-white.svg"),"alt":""}}):_vm._e()]),(!_vm.isDesktop)?_c('div',{on:{"click":function($event){_vm.showMenu = !_vm.showMenu;
            _vm.activeMenu();}}},[(!_vm.showMenu)?_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("../assets/img/menu.svg"),"alt":""}}):_vm._e(),(!_vm.isDesktop & _vm.showMenu)?_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("../assets/img/close.svg"),"alt":""}}):_vm._e()]):_vm._e()]),(_vm.isDesktop || _vm.showMenu)?_c('div',{staticClass:"col-0 col-md-8 menu-mobile"},[_c('ul',{staticClass:"header-menu-mobile"},[_c('li',[(_vm.$route.path === '/')?_c('a',{attrs:{"href":`/#/discover?position=0.0`}},[_vm._v(" "+_vm._s(_vm.$t('menu.desert')))]):_c('a',{on:{"click":function($event){_vm.isDesktop ? _vm.onSectionClick(0.0) : _vm.onSectionClickMobile('aere')}}},[_vm._v(" "+_vm._s(_vm.$t('menu.desert')))])]),_c('li',[(_vm.$route.path === '/')?_c('a',{attrs:{"href":`/#/discover?position=0.445`}},[_vm._v(" "+_vm._s(_vm.$t('menu.art')))]):_c('a',{on:{"click":function($event){_vm.isDesktop
                  ? _vm.onSectionClick(0.34)
                  : _vm.onSectionClickMobile('artists')}}},[_vm._v(" "+_vm._s(_vm.$t('menu.art')))])]),_c('li',[(_vm.$route.path === '/')?_c('a',{attrs:{"href":`/#/discover?position=0.74`}},[_vm._v(" tour")]):_c('a',{on:{"click":function($event){_vm.isDesktop
                  ? _vm.onSectionClick(0.78)
                  : _vm.onSectionClickMobile('tour')}}},[_vm._v(" tour")])]),_c('li',[(_vm.$route.path === '/')?_c('a',{attrs:{"href":`/#/discover?position=1.0`}},[_vm._v(" "+_vm._s(_vm.$t('menu.contact')))]):_c('a',{on:{"click":function($event){_vm.isDesktop
                  ? _vm.onSectionClick(1.0)
                  : _vm.onSectionClickMobile('yomi')}}},[_vm._v(" "+_vm._s(_vm.$t('menu.contact')))])]),(_vm.isDesktop)?_c('li',[_c('a',{attrs:{"href":"https://xceed.me/it/scicli/events/all/all-events","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('ticket.text'))+" ")])]):_vm._e(),(!_vm.isDesktop)?_c('li',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"text-lang color-link font-primary",on:{"click":function($event){return _vm.handleChangeEn()}}},[_vm._v("EN")]),_c('p',{staticClass:"text-lang",staticStyle:{"pointer-events":"none"}},[_vm._v(" / ")]),_c('p',{staticClass:"text-lang color-link font-primary",on:{"click":function($event){return _vm.handleChangeIt()}}},[_vm._v("IT")])])]):_vm._e()])]):_vm._e(),(_vm.isDesktop)?_c('div',{staticClass:"col-0 col-md-2 text-end"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center h-100"},[_c('p',{staticClass:"text-lang color-link font-primary",on:{"click":function($event){return _vm.handleChangeEn()}}},[_vm._v("EN")]),_c('p',{staticClass:"text-lang",staticStyle:{"pointer-events":"none"}},[_vm._v(" / ")]),_c('p',{staticClass:"text-lang color-link font-primary",on:{"click":function($event){return _vm.handleChangeIt()}}},[_vm._v("IT")])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }