<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import checkViewport from "@/mixins/checkViewport";

export default {
  mixins: [checkViewport],

  components: {},
  mounted() {
    if(this.isDesktop){
      gsap.registerPlugin(ScrollTrigger);
    }
  },
};
</script>
<style lang="scss"></style>
