<template>
  <div
    style="right: -1px;"
    :class="{ 'background-colored position-relative': isDesktop }"
    class="aere"
   
  >
  <div>

    <video class="w-100 videoRan" v-if="isDesktop" autoplay muted loop  playsinline>
     
      <source src="../assets/movie/imageRandom.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <video style="transform: scale(1.1);" class="w-100 h-100" v-if="!isDesktop" autoplay muted loop playsinline>
     
      <source src="../assets/movie/imageRandomMob.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>

  </div>

  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";

export default {
  name: "AeraSecond",
  mixins: [checkViewport],
};
</script>

<style></style>
