<template>
  <div class="artist ticket position-relative">
    <div :class="{ 'pt-4': isDesktop }" class="container-fluid">
      <div class="row pt-4 mt-1">
        <div :class="{ 'p-0': !isDesktop }" class="col-12">
             <h6>{{ $t("artist.ticket") }}</h6>
        </div>

      </div>
    </div>
   
    <div :class="isDesktop ? 'w-75  block-center-absolute' : null" class="pt-4 d-flex flex-wrap">
      <a
        :href="ticket.link"
        target="_blank"
        v-for="(ticket, index) in tickets"
        :key="index"
        class="single-ticket normal-ticket"
      >
        <div>
          <h4 class="font-primary">{{ ticket.title }}</h4>
          <p :class="isMobile ? 'medium-text' : ''">
            {{ ticket.day }} {{ $i18n.locale === 'en' ? ticket.month_en : ticket.month_it }}
          </p>
        </div>
      </a>
    </div>

  </div>
</template>

<script>
import checkViewport from "@/mixins/checkViewport";
export default {
  name: "Ticket",
  mixins: [checkViewport],
  data() {
    return {
      tickets: [
        {
          title: "IMMERSIVE TOUR",
          day: "2-10",
          month_en: "AUGUST",
          month_it: "AGOSTO",
          link: "https://xceed.me/it/scicli/event/tour-immersivo-mast-deserto--162109",
        },
        {
          title: "SECRET NIGHT",
          day: "9",
          month_en: "AUGUST",
          month_it: "AGOSTO",
          link: "https://xceed.me/it/scicli/event/secret-night-mast-deserto--157961",
        },
        {
          title: "CHURCH NIGHT",
          day: "8",
          month_en: "AUGUST",
          month_it: "AGOSTO",
          link: "https://xceed.me/it/scicli/event/ginevra-nervi-presents-the-disorder-of-appearances-a-v-performance-mast-deserto--158023",
        },
        {
          title: "MAIN NIGHT",
          day: "10",
          month_en: "AUGUST",
          month_it: "AGOSTO",
          link: "https://xceed.me/it/scicli/event/main-stage-night-mast-deserto-2024--157963",
        },
      ],
    };
  },
};
</script>

<style></style>
